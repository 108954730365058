```vue
<template>
  <v-card>
    <v-toolbar flat color="primary" dark>
      <v-toolbar-title>Liste des équipes et leurs droits</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <v-row class="my-4">
        <v-col cols="12" sm="12">
          <v-text-field
            v-model="search"
            label="Rechercher"
            append-icon="mdi-magnify"
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>

      <v-progress-linear
        v-if="loading"
        indeterminate
        color="primary"
      ></v-progress-linear>

      <v-alert v-else-if="teamRoles.length === 0" type="info" text>
        Aucun utilisateur trouvé
      </v-alert>

      <v-expansion-panels v-else>
        <v-expansion-panel
          v-for="(members, teamName) in groupedUsers"
          :key="teamName"
        >
          <v-expansion-panel-header>
            <div class="d-flex align-center">
              <v-icon left color="primary" small>mdi-account-group</v-icon>
              {{ teamName }}
              <v-chip small class="ml-2" color="primary"
                >{{ members.length }} membres</v-chip
              >
            </div>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-row>
              <v-col
                v-for="member in members"
                :key="member._id"
                cols="12"
                sm="6"
                md="4"
              >
                <v-card outlined>
                  <v-list-item>
                    <v-list-item-avatar color="primary">
                      <span class="white--text">{{
                        getInitials(getUserName(member.teamRole.userId))
                      }}</span>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>{{
                        getUserName(member.teamRole.userId)
                      }}</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action class="mx-0">
                      <v-btn icon small @click="editMember(member)">
                        <v-icon small>mdi-pencil</v-icon>
                      </v-btn>
                    </v-list-item-action>

                    <v-list-item-action class="ml-2">
                      <v-btn
                        icon
                        small
                        color="error"
                        @click="confirmDelete(member)"
                      >
                        <v-icon small>mdi-delete</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>

                  <v-divider></v-divider>
                  <v-card-text v-if="member.teamRole && member.teamRole.roles">
                    <div class="mb-2">
                      <span class="text-caption grey--text text--darken-1"
                        >Planning</span
                      >
                      <div>
                        <v-chip
                          x-small
                          :color="
                            getScopeColor(member.teamRole.roles.planning.scope)
                          "
                        >
                          {{
                            getPlanningLabel(
                              member.teamRole.roles.planning.scope
                            )
                          }}
                        </v-chip>
                        <v-chip
                          x-small
                          :color="
                            getScopeColor(
                              member.teamRole.roles.planning.dragDrop
                            )
                          "
                          class="ml-1"
                        >
                          {{
                            getDragDropLabel(
                              member.teamRole.roles.planning.dragDrop
                            )
                          }}
                        </v-chip>
                      </div>
                    </div>

                    <div>
                      <span class="text-caption grey--text text--darken-1"
                        >Tâches</span
                      >
                      <div>
                        <v-chip
                          v-if="member.teamRole.roles.tasks.create"
                          x-small
                          color="primary"
                          class="mr-1"
                        >
                          Créer cartes
                        </v-chip>
                        <v-chip
                          v-if="member.teamRole.roles.tasks.viewAllTeams"
                          x-small
                          color="primary"
                          class="mr-1"
                        >
                          Voir tous
                        </v-chip>
                        <v-chip
                          v-if="member.teamRole.roles.tasks.viewTeamTasks"
                          x-small
                          color="info"
                          class="mr-1"
                        >
                          Voir équipe
                        </v-chip>
                        <v-chip
                          v-if="member.teamRole.roles.tasks.writeOwnTasks"
                          x-small
                          color="success"
                          class="mr-1"
                        >
                          Modifier perso
                        </v-chip>
                        <v-chip
                          v-if="member.teamRole.roles.tasks.writeTeamTasks"
                          x-small
                          color="warning"
                          class="mr-1"
                        >
                          Modifier équipe
                        </v-chip>
                        <v-chip
                          v-if="member.teamRole.roles.tasks.editOthersTasks"
                          x-small
                          color="error"
                        >
                          Modifier autres
                        </v-chip>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>

    <!-- Dialogs -->
    <v-dialog v-model="deleteDialog" max-width="400">
      <v-card>
        <v-card-title>Confirmation</v-card-title>
        <v-card-text v-if="selectedMember">
          Voulez-vous vraiment retirer
          <strong>{{ getUserName(selectedMember.teamRole.userId) }}</strong> de
          son équipe ?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="deleteDialog = false">Annuler</v-btn>
          <v-btn color="error" text :loading="loading" @click="handleDelete"
            >Supprimer</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editDialog" max-width="700">
      <team-role-update
        v-if="editDialog && selectedMember"
        :member="selectedMember"
        @close-modal="closeEditModal"
        @update="loadData"
      ></team-role-update>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import DroitService from "@/Services/SupportVision/DroitService";
import UserService from "@/Services/UserService";
import TeamRoleUpdate from "./editTeamRole.vue";

export default {
  name: "ListeTeamRole",

  components: {
    TeamRoleUpdate,
  },

  data() {
    return {
      search: "",
      teamRoles: [],
      users: {},
      loading: false,
      deleteDialog: false,
      editDialog: false,
      selectedMember: null,
    };
  },

  computed: {
    ...mapState(["droitSupportVision", "informations"]),

    groupedUsers() {
      return this.teamRoles.reduce((groups, member) => {
        if (!member.teamRole) return groups;
        const team = member.teamRole.team || "Non assigné";

        if (!groups[team]) {
          groups[team] = [];
        }

        if (this.matchesSearch(member)) {
          groups[team].push(member);
        }

        return groups;
      }, {});
    },
  },

  methods: {
    matchesSearch(member) {
      if (!this.search) return true;
      const searchTerm = this.search.toLowerCase();
      const userName = this.getUserName(member.teamRole.userId).toLowerCase();
      return userName.includes(searchTerm);
    },

    getInitials(name) {
      if (!name) return "NA";
      return name
        .split(" ")
        .map((n) => n[0])
        .join("")
        .toUpperCase();
    },

    getDragDropLabel(scope) {
      const labels = {
        all: "Drag tous",
        team: "Drag équipe",
        self: "Drag perso",
      };
      return labels[scope] || "Non défini";
    },
    getUserName(userId) {
      return this.users[userId] || "Utilisateur inconnu";
    },

    getScopeColor(scope) {
      const colors = {
        all: "deep-purple",
        team: "indigo",
        self: "blue",
      };
      return colors[scope] || "grey";
    },

    getPlanningLabel(scope) {
      const labels = {
        all: "Tous",
        team: "Équipe",
        self: "Personnel",
      };
      return labels[scope] || "Non défini";
    },

    editMember(member) {
      this.selectedMember = {
        ...member,
        user: {
          firstname: this.getUserName(member.teamRole.userId).split(" ")[0],
          lastname: this.getUserName(member.teamRole.userId).split(" ")[1],
        },
      };
      this.editDialog = true;
    },

    closeEditModal() {
      this.editDialog = false;
      this.selectedMember = null;
    },

    confirmDelete(member) {
      this.selectedMember = member;
      this.deleteDialog = true;
    },

    async handleDelete() {
      if (!this.selectedMember?.teamRole?.userId) return;

      try {
        this.loading = true;
        await DroitService.removeTeamAndRole(
          this.selectedMember.teamRole.userId
        );
        await this.loadData();
        this.$nSuccess("Membre retiré avec succès");
        this.deleteDialog = false;
        this.selectedMember = null;
      } catch (error) {
        console.error("Erreur lors de la suppression:", error);
        this.$nError("Erreur lors de la suppression");
      } finally {
        this.loading = false;
      }
    },

    async loadData() {
      try {
        this.loading = true;
        const [usersResponse, rolesResponse] = await Promise.all([
          UserService.getUsers(),
          DroitService.getTeamAndRole(),
        ]);

        this.users = usersResponse.reduce((acc, user) => {
          acc[user.id] = `${user.firstname} ${user.lastname}`;
          return acc;
        }, {});

        this.teamRoles = rolesResponse.data || [];
      } catch (error) {
        console.error("Erreur chargement données:", error);
        this.$nError("Erreur chargement données");
        this.teamRoles = [];
        this.users = {};
      } finally {
        this.loading = false;
      }
    },
  },

  mounted() {
    this.loadData();
  },
};
</script>

<style scoped>
.v-card-text {
  padding: 12px;
}

.v-list-item__action {
  margin: 4px !important;
}

.v-chip {
  font-weight: 500 !important;
}
</style>
```
